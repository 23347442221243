var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"home d-flex"},[_c('div',{staticClass:"intro-text d-flex align-items-end px-md-5"},[_c('div',[_c('h1',[_vm._v("Bory Promenáda")]),_c('p',[_vm._v("Vďaka svojej tesnej blízkosti k Bory Mall je tretia etapa charakteristická výbornou občianskou vybavenosťou. Tá prináša pešiu vzdialenosť k obchodom, do kina, fitnes centra či k 25 m plaveckému bazénu.")]),_c('b-button',{staticClass:"d-block d-md-inline-block",attrs:{"to":"/nasa-stvrt","squared":"","variant":"primary"}},[_vm._v("O bývaní na Boroch")])],1)]),_vm._m(0)]),_c('section',{staticClass:"district p-md-3 pt-3 pt-md-5"},[_vm._m(1),_c('div',{attrs:{"id":"mapbox"}},[_c('B3Map')],1)]),_c('section',{attrs:{"id":"tabulka"}},[_c('FlatsTable',{attrs:{"stages":['bory3'],"showStageAndDate":false,"show-vr":false}})],1),_c('section',{staticClass:"section-3 free-offer p-3"},[_c('div',{},[_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"col-md-5 p-0 px-md-5 py-5"},[_vm._m(2),_vm._m(3),_c('b-button',{staticClass:"d-block d-md-inline-block px-5",attrs:{"to":"/zaluzie-a-kuchyna-v-cene-bytu","squared":"","variant":"primary"}},[_vm._v("Viac o akcii")])],1),_c('div',{staticClass:"col-md-7 bg-img"})])])]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('ContactForm')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"badge-offer"},[_c('a',{attrs:{"href":"/zaluzie-a-kuchyna-v-cene-bytu"}},[_c('img',{attrs:{"src":require("../assets/images/kuchyne/badge_promenada_zaluzie.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap align-items-start top mb-5"},[_c('div',{staticClass:"col-md-6 px-md-5"},[_c('h2',{staticClass:"mb-4 m-md-0"},[_vm._v("Mesto tak, ako ho poznáte")])]),_c('div',{staticClass:"col-md-6 d-flex justify-content-center"},[_c('p',{staticClass:"m-0"},[_vm._v("Bory kombinujú aktívne rodinné bývanie s bohatým verejným priestorom, kompletnou vybavenosťou, množstvom obchodov a pohodlnou dopravou do centra.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"mb-4"},[_vm._v("Posledné byty so žalúziami "),_c('br'),_vm._v("a kuchyňou "),_c('br'),_vm._v("ZADARMO")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-5"},[_vm._v("Získajte vybavenie v hodnote "),_c('br'),_vm._v("až do 12 000€ v cene bytu")])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"schedule p-3 p-md-5"},[_c('h2',{staticClass:"mb-5"},[_vm._v("Harmonogram výstavby")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"col-md p-0 phase fill"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/zahajenie-vystavby.svg")}})]),_c('h3',[_vm._v("Začiatok"),_c('br'),_vm._v(" výstavby")]),_c('div',{staticClass:"line fill"}),_c('p',{staticClass:"date"},[_vm._v("3. kvartál 2020")])]),_c('div',{staticClass:"col-md p-0 phase fill"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/hruba-stavba.svg")}})]),_c('h3',[_vm._v("Žiadosť"),_c('br'),_vm._v(" o kolaudáciu")]),_c('div',{staticClass:"line fill"}),_c('p',{staticClass:"date"},[_vm._v("3. kvartál 2022")])]),_c('div',{staticClass:"col-md p-0 phase fill"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/kolaudacia.svg")}})]),_c('h3',[_vm._v("Kolaudačný"),_c('br'),_vm._v(" proces")]),_c('div',{staticClass:"line fill"}),_c('p',{staticClass:"date"},[_vm._v("3. - 4. kvartál 2022")])]),_c('div',{staticClass:"col-md p-0 phase fill"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/odovzdanie-bytov.svg")}})]),_c('h3',[_vm._v("Odovzdávanie"),_c('br'),_vm._v(" bytov")]),_c('div',{staticClass:"line fill"}),_c('p',{staticClass:"date"},[_vm._v("1. - 3. kvartál 2023")])]),_c('div',{staticClass:"col-md p-0 phase fill"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/kluce-byvanie.svg")}})]),_c('h3',[_vm._v("Vaše"),_c('br'),_vm._v(" bývanie")]),_c('div',{staticClass:"line fill transparent"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section-3 p-3"},[_c('div',{},[_c('div',{staticClass:"row px-3 mb-3"},[_c('div',{staticClass:"col-md-7 bg-img"}),_c('div',{staticClass:"col-md-5 p-0 px-md-5 py-3"},[_c('h2',[_vm._v("Buďte pri tom,"),_c('br'),_vm._v(" čo je dostupné")]),_c('p',[_vm._v("Rovnako ako v 1. a 2. etape aj túto vystihujú veľkorysé zelené plochy o rozlohe až 8 500 m"),_c('sup',[_vm._v("2")]),_vm._v(". Obyvateľov potešia kilometre cyklotrás, multifunkčné ihriská a blízkosť najmodernejšej nemocnice v strednej Európe. Rodiny navyše ocenia škôlku v pešej blízkosti. Vďaka blízkosti k diaľnici prináša lokalita Bory výbornú dostupnosť.")])])]),_c('div',{staticClass:"row px-2"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-12 p-md-5 bg-col bg-col-1"},[_c('h3',[_vm._v("8 500 m"),_c('sup',[_vm._v("2")]),_c('br'),_vm._v(" zelene")]),_c('p',[_vm._v("Centrálna časť je bezpečná pre obyvateľov i malé deti vďaka oddeleniu automobilovej dopravy umiestnenej len po obvode novej štvrte.")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-12 p-md-5 bg-col bg-col-2"},[_c('h3',[_vm._v("488"),_c('br'),_vm._v(" parkovacích miest")]),_c('p',[_vm._v("Dostupných bude celkovo 320 vonkajších a 168 vnútorných parkovacích miest a samozrejme aj elektro stanice.")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-12 p-md-5 bg-col bg-col-3"},[_c('h3',[_vm._v("3"),_c('br'),_vm._v(" detské ihriská")]),_c('p',[_vm._v("Pre najmladších obyvateľov staviame detské ihriská, ktoré sú v každej etape projektu nevyhnutnosťou a tiež novú škôlku.")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"about-bottom p-md-3 pl-md-5"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"col-md-6 py-4"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Domov, ktorý zažiari")]),_c('p',{staticClass:"mb-3"},[_vm._v("Vďaka širokej ponuke štandardu si každý obyvateľ vyberie podľa svojich predstáv. "),_c('br'),_vm._v("V snahe zabezpečiť vášmu bývaniu dlhú životnosť sme pre vás vybrali kvalitné materiály dostupné v rôznych farebných prevedeniach.")])]),_c('div',{staticClass:"col-md-6 pb-3 pb-md-0 pr-md-0"},[_c('div',{staticClass:"right-img"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"about-bottom-2"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"col-md-6 bg"}),_c('div',{staticClass:"col-md-6 signature-section"},[_c('p',{staticClass:"mb-4 text-italic font-weight-bolder"},[_vm._v("„Tretia etapa projektu ponúka štýlové a moderné bývanie v ôsmich bytových domoch, obkolesených množstvom zelene a kvalitným verejným priestorom s ústredným lineárnym parkom, ktorého súčasťou sú detské ihriská a fitnes plocha.“")]),_c('p',{staticClass:"name"},[_vm._v("Sadovsky&Architects")]),_c('p',{staticClass:"name"},[_vm._v("Mateja Vonkomerová a Silvia Rosíková, Oliver Sadovský")])])])])
}]

export { render, staticRenderFns }